import React from 'react';
import { useClientsStore } from '../../ClientsStore';
import {
  Button,
  InputWidthLabel,
  TextArea,
} from '../BasicComponents/BasicComponents';
import styles from './EditContactForm.module.css';
import avatarImage from '../Images/contact-avatar.png';
import shapeImage from '../Images/Shape.svg';
import close from '../Images/Close.svg';
import { useTranslation } from 'react-i18next';
import PhoneNumberInput from 'Components/PhoneNumberInput/PhoneNumberInput';

function ContactForm() {
  const { t } = useTranslation();
  let { client, inputHandler, updateClient, showConfirmationModal } =
    useClientsStore();

  return (
    <div className={styles.contactForm}>
      <div className={styles.contactForm_contactName}>
        <InputWidthLabel
          id={'name'}
          onChange={inputHandler}
          value={client.name}
          name="name"
          type="text"
          label={t('first_name')}
        />
        <InputWidthLabel
          id={'surname'}
          onChange={inputHandler}
          value={client.surname}
          name="surname"
          type="text"
          label={t('last_name')}
        />
      </div>
      <PhoneNumberInput
        id={'phone'} 
        className={styles.contactForm_telephone}
        defaultValue={client.phone} 
        handleChange={(value, country, event) => inputHandler(value, 'phone')} 
        name="tel"
        type="tel"
        label={t('phone')}
      />
      <InputWidthLabel
        id={'email'}
        onChange={inputHandler}
        value={client.email}
        className={styles.contactForm_telephone}
        name="email"
        type="email"
        label={t('client_email')}
      />

      <TextArea
        id={'comment'}
        onChange={inputHandler}
        name={`${t('description')}:`}
        value={client.comment}
      />
      <div className={styles.contactForm_manager}>
        <img
          onClick={showConfirmationModal}
          className={styles.contactForm_shape_image}
          src={shapeImage}
          alt="shape"
        />
        <Button
          onClick={updateClient}
          style={{ width: '200px' }}
          name={t('save')}
        />
      </div>
    </div>
  );
}

function EditContactForm() {
  let { editContactForm, hideEditContactForm } = useClientsStore();

  if (editContactForm) {
    return (
      <div className={styles.addContactForm_container}>
        <div className={styles.addContactForm_block}>
          <div className={styles.addContactForm_avatar}>
            <img src={avatarImage} alt="avatar" />
          </div>
          <img
            onClick={hideEditContactForm}
            className={styles.close_img}
            src={close}
            alt=""
          />
          <ContactForm />
        </div>
      </div>
    );
  }
}

export default EditContactForm;
