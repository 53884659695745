import axios from 'axios';
import { useToast2 } from 'Components/Toast2/ToastStore';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import { create } from 'zustand';
import { checkAccess } from '../../access';

let { showToast } = useToast2.getState();

export let useSettingsStore = create((set, get) => ({
  modal: {
    isOpenPassword: false,
    isOpenEmail: false,
  },

  isOpenPassword: false,
  resetPassword: {
    oldPassword: '',
    newPassword: '',
    newPasswordRepeat: ''
  },
  resetEmail: {
    email: '',
    newEmail: '',
    verificationCode: '',
  },
  email: undefined,
  errors: undefined,
  verification: false,

  settings: {
    country: "",
    timeZone: "",
    language: i18n.language,
    currency: "",
  },

  inputHandler: (e, type = 'password') => {
    set({ errors: null });

    if (type === 'email') {
      set({ resetEmail: { ...get().resetEmail, [e.target.id]: e.target.value } });
      return
    }

    set({ resetPassword: { ...get().resetPassword, [e.target.id]: e.target.value } });
  },

  inputSettingsHandler: (e, key, values) => {
    const id = e?.target?.id || key
    const value = e?.target?.value || values

    if (id === 'language') {
      i18n.changeLanguage(value)
      Cookies.set('language', value)
      get().editSpecialistsSettings('language', value)
    }

    if (id === 'currency') {
      Cookies.set('currencyId', value)
      get().editSpecialistsSettings('currency', value)
    }

    if (id === 'country') {
      Cookies.set('country', value)
      get().editSpecialistsSettings('country', value)
    }

    set({ settings: { ...get().settings, [id]: value } });
  },

  handleOpenModal: (name, value = true) => {
    set({ errors: undefined });
    set({ modal: { ...get().modal, [name]: value } });
  },

  deleteCash: () => {
    Cookies.remove('sToken', {
      domain: process.env.REACT_COOKIE_DOMAIN,
    });
    Cookies.remove('token', {
      domain: process.env.REACT_COOKIE_DOMAIN,
    });
    window.location.href = '/login';
    return showToast('e', "Токен застарів!");
  },

  passwordResetSubmit: async () => {
    const originalPassword = get().resetPassword.oldPassword
    const newPassword = get().resetPassword.newPassword
    const newPasswordRepeat = get().resetPassword.newPasswordRepeat
    const token = Cookies.get('sToken');

    if (newPassword !== newPasswordRepeat) {
      const message = i18n.t('passwords_do_not_match')
      showToast('e', message);
      set({ errors: message });

      return;
    }

    if (!originalPassword || !newPassword) {
      const message = i18n.t('field_cannot_be_empty')
      showToast('e', message);
      set({ errors: message });

      return;
    }

    try {
      let { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/specialist/change_password`,
        { token, password: newPassword, confirmedPassword: newPasswordRepeat, originalPassword },
        { withCredentials: true },
      );

      if (data === 'Invalid token') {
        const message = i18n.t('password_change_timeout')
        showToast('e', message);
        set({ errors: message });

        return;
      }
      showToast('s', i18n.t('password_updated'));

    } catch (error) {
      let message = error.response.data

      if (error.response.status === 403) {
        get().deleteCash()
      }

      if (error.response.data === "The old password doesn't match") {
        message = i18n.t('old_password_mismatch')
        return;
      }

      showToast('e', message);
    }
  },

  emailResetSubmit: async () => {
    const token = Cookies.get('sToken');
    const email = get().resetEmail.email
    const newEmail = get().resetEmail.newEmail
    const verificationCode = get().resetEmail.verificationCode

    try {
      let { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/specialist/change_email`,
        { token, email, newEmail, verificationCode },
        { withCredentials: true },
      );

      if (data === "success") {
        set({ verification: false });
        set({ modal: { isOpenEmail: false } });
        showToast('s', "Success!");
        return
      }

      set({ verification: true });

    } catch (error) {
      if (error.response.status === 403) {
        get().deleteCash()
      }

      showToast('e', error.response.data.message);
    }
  },

  editSpecialistsSettings: async (key, value) => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
      { withCredentials: true },
    );

    let specialist = data;

    if (specialist.settings[key] === value) {
      console.error('Error: specialist[key] === value');
      return
    }

    specialist.settings = {
      ...specialist.settings,
      [key]: value
    }

    //Изменить настройки
    let response = await axios.put(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
      specialist,
      { withCredentials: true },
    );
  },
}));
