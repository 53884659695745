import React from 'react';
import styles from './AddClient.module.css';
import backImg from '../Images/Vector.svg';
import {
  Button,
  InputWidthLabel,
  TextArea,
} from '../BasicComponents/BasicComponents';
import { useMobileClientsStore } from '../MobileClientsStore';
import { useTranslation } from 'react-i18next';
import PhoneNumberInput from 'Components/PhoneNumberInput/PhoneNumberInput';

function AddClient() {
  const { t } = useTranslation();
  let { goToPage, client, inputHandler, saveClient } = useMobileClientsStore();

  return (
    <div className={styles.addClient_container}>
      <div className={styles.addClient_header}>
        <img
          onClick={() => {
            goToPage('AllClients');
          }}
          src={backImg}
          alt="back"
        />
        <h1 className={styles.addClient_title}>{t('add_contact')}</h1>
      </div>
      <div className={styles.addClient_form}>
        <InputWidthLabel
          id={'name'}
          value={client.name}
          onChange={inputHandler}
          name="name"
          label={t('name')}
          type="name"
        />
        <InputWidthLabel
          id={'surname'}
          value={client.surname}
          onChange={inputHandler}
          name="surname"
          label={t('last_name')}
          type="name"
        />

        <PhoneNumberInput
          id={'phone'} 
          className={styles.contactForm_telephone}
          handleChange={(value, country, event) => inputHandler(value, 'phone')} 
          name="tel"
          type="tel"
          label={t('phone')}
        />
        
        <InputWidthLabel
          id={'email'}
          value={client.email}
          onChange={inputHandler}
          name="email"
          label={t('client_email')}
        />
        <TextArea
          id={'comment'}
          value={client.comment}
          label={t('comment')}
          onChange={inputHandler}
          classNameTextArea={styles.addClient_textarea}
        />
        <div style={{ height: '80px' }}></div>
        <Button
          onClick={saveClient}
          className={styles.addClient_button}
          label={t('add_contact')}
        />
      </div>
    </div>
  );
}

export default AddClient;
