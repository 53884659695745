import { create } from 'zustand';
import axios from 'axios';
import { checkAccess } from '../../../access';
import { useToast2 } from '../../../Components/Toast2/ToastStore';
import i18n from 'i18n';
let { showToast } = useToast2.getState();

export let useMobileClientsStore = create((set, get) => ({
  page: 'AllClients',
  confirmationModal: false,
  clients: [],
  filteredClients: [],
  client: {
    name: '',
    surname: '',
    phone: '+380',
    email: '',
    comment: '',
    specialistId: '',
  },
  message: { clientId: '', clientName: '', channel: 'sms', msg: '' },
  sms: { symbols: 0, numberOfsms: 0 },

  searchValue: undefined,

  getSpecialistsClients: async () => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/clients/specialist/${checkAccess()}`,
    );
    set({ clients: data });
    set({ message: { clientId: '', clientName: '', channel: 'sms', msg: '' } });
  },

  goToPage: async (p, clientId, clientName) => {
    if (p == 'AddClient') {
      set({
        client: {
          name: '',
          surname: '',
          phone: '+380',
          email: '',
          comment: '',
          specialistId: '',
        },
      });
    }
    if (p == 'Send Message') {
      set({
        message: {
          ...get().message,
          'clientId': clientId,
          'clientName': clientName,
        },
      });
    }

    set({ page: p });
  },

  inputHandler: (e, type= '') => {
    let client = get().client;
    
    if (type === 'phone' ) {
      set({ client: { ...client, phone: e } });

      return;
    }

    set({ client: { ...client, [e.target.id]: e.target.value } });
  },

  saveClient: async () => {
    let client = get().client;

    //Валідація
    if (client.name.length == 0) {
      return;
    }

    client.specialistId = checkAccess();
    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/clients`,
      client,
      { withCredentials: true },
    );

    if (data) {
      get().getSpecialistsClients();
      get().goToPage('AllClients');
      set({
        client: {
          name: '',
          surname: '',
          phone: '',
          email: '',
          comment: '',
          specialistId: '',
        },
      });
    }
  },

  updateClient: async () => {
    let { data } = await axios.put(
      `${process.env.REACT_APP_API}/api/clients/`,
      get().client,
    );

    if (data) {
      get().getSpecialistsClients();
      get().goToPage('AllClients');
      set({
        client: {
          name: '',
          surname: '',
          phone: '',
          email: '',
          comment: '',
          specialistId: '',
        },
      });
    }
  },

  deleteClient: async () => {
    let { data } = await axios.delete(
      `${process.env.REACT_APP_API}/api/clients/${get().client._id}`,
    );
    get().getSpecialistsClients();
    get().goToPage('AllClients');
    set({ confirmationModal: false });
  },

  //Форма редагування контакту
  showEditPage: async (clientId) => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/clients/${clientId}`,
    );
    set({ page: 'EditClient' });
    set({ client: data });
  },

  //Вікно підтвердження видалення контакту

  showConfirmationModal: () => {
    set({ confirmationModal: true });
  },

  hideConfirmationModal: () => {
    set({ confirmationModal: false });
  },

  filterClients: (e) => {
    set({ searchValue: e.target.value });

    let filtered = get().clients.filter((client) => {
      return client.name.includes(e.target.value);
    });
    if (filtered.length == 0) {
      filtered = get().clients.filter((client) => {
        return client.phone.includes(e.target.value);
      });
    }

    set({ filteredClients: filtered });
  },

  msgModalInputHandler: (e) => {
    set({ message: { ...get().message, [e.target.id]: e.target.value } });

    let smsLength =
      get().message.msg.length + get().specialist.visibleName.length + 2;

    set({
      sms: {
        ...get().sms,
        'symbols': smsLength,
        'numberOfsms': Math.ceil(smsLength / 70),
      },
    });
  },

  getSpecialistInfo: async () => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
    );

    set({ specialist: data });
    set({ sms: { ...get().sms, 'symbols': data.visibleName.length + 2 } });
  },

  sendMessageToClient: async () => {
    let message = get().message;

    if (message.msg.length == 0) {
      showToast('e', i18n.t('message_cannot_be_empty'));
      return;
    }

    message.specialistId = checkAccess();

    message.msg = `${message.msg} "${get().specialist.visibleName}"`;

    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/specialist/send_message`,
      message,
    );

    if (data == 'client does not have email') {
      showToast('e', i18n.t('add_client_email_for_notification'));
      return;
    }
    if (data == 'client does not have phone number') {
      showToast('e', i18n.t('client_phone_number_missing'));
      return;
    }

    if (data == 'noSMS') {
      showToast('e', i18n.t('insufficient_sms_balance'));
      return;
    }
    if (data) {
      get().goToPage('AllClients');
      showToast('s', i18n.t('message_sent_successfully'));
      return;
    }
  },
}));
