
import React, {  useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import styles from './PhoneNumberInput.module.css';



const PhoneNumberInput = (props) => {
  const [phone, setPhone] = useState(props.defaultValue ?? '');

  const { handleChange, ...propsData } = props;

  return (
    <>
      {
        props.label && (
          <label className={styles.label} for={props.name}>
            {props.label}
          </label>
        )
      }
    
      <PhoneInput
        country={'ua'} 
        value={phone}
        onChange={(value, country, event) => {
          setPhone(value);
          handleChange(`+${value}`, country, event); 
        }} 

        preserveOrder={['priority', 'preferredCountries']}
        preferredCountries={['ua','de', 'us', 'pl']}

        inputClass={ styles.inputClass}
        buttonClass={ styles.buttonClass}
        {...propsData}
      />
    </>
  );
};

export default PhoneNumberInput;
